import React from 'react';
import Layout from '../components/Layout';
import Information from '../components/Information';
import Map from '../components/Map';
import Conditions from '../components/Conditions';

const RequestConfirmation = () => {
  return (
    <Layout>
      <div className="w-full bg-gray-200 flex items-center justify-center py-16 px-8 lg:px-16">
        <p className="text-sunstate-primary text-3xl text-center">
          Your appointment has been requested. Please note this is just an
          appointment request and our office will follow up with you to confirm
          an appointment. Thank you.
        </p>
      </div>
      <Conditions />
      <Information />
      <Map />
    </Layout>
  );
};

export default RequestConfirmation;
